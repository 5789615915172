export enum SocialLinks {
  Instagram = 'https://www.instagram.com/brainway.app/',
  Facebook = 'https://www.facebook.com/people/Brainway/100094037230804/',
  Twitter = '#',
  Reddit = '#',
}
export enum StoresLinks {
  AppleStore = 'https://apps.apple.com/us/app/brainway-beat-procrastination/id6450369743',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.brainway.app',
}
