import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import axios from 'axios';
import styled from 'styled-components';
import Text from 'components/Text';
import { Loader, LoadingPage, PageContainer, Seo, TextInput } from 'components';
import Header from 'components/Header';
import { tablet, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from 'config';
import Button from 'components/PrimaryButton';
import { usePageView, useQuizData } from 'utils/hooks';
import RegisterCompleted from './components/RegisterCompleted';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';

interface FormData {
  email: string;
  emailConfirm: string;
  password?: string;
  repeatedPassword?: string;
}

const defaultValues: FormData = {
  email: '',
  emailConfirm: '',
  password: undefined,
  repeatedPassword: undefined,
};

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 1rem;
  gap: 3rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
    gap: 2rem;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

const Title = styled(Text)`
  width: 100%;
`;
const Subtitle = styled(Text)`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  max-width: 38rem;
  padding: 0 1rem;
  padding-bottom: 1rem;

  @media ${tablet} {
    width: 100%;
    max-width: 100%;
    padding: 0rem;
    border: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;

  @media ${tablet} {
    width: 100%;
  }
`;

const FormContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding-bottom: 3rem;

  @media ${tablet} {
    gap: 2rem;
  }
  @media ${tablet} {
    padding-bottom: 2rem;
  }
`;

const Register: FC = () => {
  const [loading, setLoading] = useState(false);

  const { isTablet } = useQuery();

  const [errors, setErrors] =
    useState<Partial<Record<keyof FormData, string>>>();
  const [formValues, setFormValues] = useState<FormData>(defaultValues);
  const [isRegisterComplete, setIsRegisterComplete] = useState<boolean>(false);

  const { quiz_answers, user, code, selected_plan, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const upgradeSequences = useQuizData('upgradeSequences');

  const data = useQuizData('register');

  const validateSchema = z
    .object({
      email: z
        .string({ required_error: 'Please enter your email' })
        .email('Please enter a valid email address'),
      emailConfirm: z.string(),
      password: z
        .string({ required_error: data?.requirePasswordError })
        .min(6, data?.passwordValidationError),
      repeatedPassword: z.string({
        required_error: data?.confirmPassword,
      }),
    })

    .refine(data => data.password === data.repeatedPassword, {
      message: data?.passwordMismatchError,
      path: ['repeatedPassword'],
    });

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
  });

  const { goToUpgrade } = useRouter();

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-bw';
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  useEffect(() => {
    if (user) {
      formValues.email = user.email;
      formValues.emailConfirm = user.email;
      setFormValues(formValues);
    }
  }, [user]);

  const handleRegister = async (data: FormData) => {
    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    const userData = {
      email: data.email,
      password: data.password,
    };

    // const onboardingBooked = sessionStorage.getItem('onboardingBooked');

    const funnelData = {
      code: code ?? '',
      quizAnswers: quiz_answers ?? {},
      selectedPlan: selected_plan ?? {},
      quiz,
      onboardingBooked: false,
    };

    try {
      await axios.post(config.FIREBASE_REGISTER_URL, {
        user: userData,
        funnel: funnelData,
      });

      Tracking.logEvent({
        event: 'SuccessfulRegistration',
        clientcode: funnelData.code,
      });
      // if user came from support email - prevent going to upgrade sequence
      if (isFromSupportCase) {
        setIsRegisterComplete(true);
        setLoading(false);
        return;
      }
      const selectedPlanKey = selected_plan?.key || null;
      const upgradeKey = selectedPlanKey
        ? Object.keys(upgradeSequences[selectedPlanKey])[0]
        : null;
      if (upgradeKey) {
        goToUpgrade(upgradeKey);
      } else {
        goToUpgrade('upgrade');
      }
    } catch (error) {
      console.error(error);

      setLoading(false);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.scrollIntoView();
  };

  const validateInputs = async (data: FormData) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse(data);
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const validateInput = async (data: Partial<FormData>, field: string) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse({
        ...formValues,
        [field]: data[field as keyof FormData],
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          if (e.path[0] !== field) return;
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    const isEmpty = Object.keys(validationErrors).length === 0;
    if (isEmpty) {
      return setErrors({ ...errors, [field]: null });
    }
    return setErrors({ ...errors, ...validationErrors });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = await validateInputs(formValues);
    if (Object.keys(validationErrors).length === 0) {
      handleRegister(formValues);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    await validateInput(
      { [event.target.name]: event.target.value },
      event.target.name,
    );
  };

  const renderBottomComponent = () => {
    if (loading) return <Loader wrapperHeight={'1'} />;
    if (isRegisterComplete) return <RegisterCompleted />;
    return <StyledButton type="submit">{data?.submitBtnTitle}</StyledButton>;
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="center" RightComponent={null} color="light0" />
      <PageContainer>
        <ContentContainer>
          <TitleContainer>
            <Title
              type={isTablet ? 'h3' : 'h1'}
              textAlign={isTablet ? 'left' : 'center'}
              color="dark100"
            >
              {data?.title}
            </Title>

            <Subtitle
              type={isTablet ? 'bodyM' : 'h3400'}
              textAlign={isTablet ? 'left' : 'center'}
              color="dark100"
            >
              {data?.subtitle}
            </Subtitle>
          </TitleContainer>

          <Form onSubmit={handleSubmit}>
            <FormContentContainer>
              <InputWrapper>
                <TextInput
                  name="email"
                  defaultValue={user?.email ?? ''}
                  label={data?.emailLabel}
                  error={errors?.email}
                  disabled={true}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <TextInput
                  name="emailConfirm"
                  defaultValue={user?.email ?? ''}
                  label={data?.confirmEmailLabel}
                  error={errors?.emailConfirm}
                  disabled={true}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <TextInput
                  type="password"
                  name="password"
                  label={data?.passwordLabel}
                  error={errors?.password}
                  disabled={loading}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <TextInput
                  type="password"
                  name="repeatedPassword"
                  label={data?.confirmPasswordLabel}
                  error={errors?.repeatedPassword}
                  disabled={loading}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                />
              </InputWrapper>
            </FormContentContainer>
            {renderBottomComponent()}
          </Form>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const InputWrapper = styled.div`
  label {
    font-weight: 500;
    font-size: 1rem;
  }
  input:disabled {
    background: ${({ theme }) => theme.colors.light0};
  }
`;

export default Register;
